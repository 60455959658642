* {
    font-family: Roboto, sans-serif;
}

.content-wrapper {
    display: flex
}

a {
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }

    &[disabled] {
        opacity: 0.5;
        pointer-events: none;
        display: block;
    }
}

.nav-link-active {
    color: white;

    .MuiListItem-root {
        color: white;
    }
}

.action-button-position {
    position: absolute;
    bottom: 64px;
    right: 64px;
}
